.messageInputWrapper {
  display: flex;
  padding: 36px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.messageInputWrapper .voiceButton {
  line-height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageInputWrapper .voiceIcon {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.messageInputWrapper .voiceRecordingIcon {
  height: 80px;
}

.messageInputWrapper .textMessageInputWrapper {
  display: flex;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 28px;
  height: 64px;
}

.messageInputWrapper .messageInput {
  flex: 1;
  box-sizing: border-box;
  height: 64px;
  background-color: #c9cace;
  border-radius: 4px;
  border: 1px solid #5a5a5a;
  color: #193a5a;
  line-height: 64px;
  font-size: 20px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.messageInputWrapper .messageInputPlaceholder {
  flex: 1;
  height: 88px;
  line-height: 88px;
  color: #8a8b8e;
  font-size: 32px;
}

.messageInputWrapper .messageSendButton {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  height: 100%;
  line-height: 64px;
  background-color: rgb(233, 233, 233);
  border: 1px solid #9a9b9e;
  color: #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: center;
}
